import React from "react"
import styled from "styled-components"

const DisplayH1 = ({ children }) => {
	return <H4>{children}</H4>
}

export default DisplayH1

const H4 = styled.h4`
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 42px;
	line-height: 46px;
`
