import React from "react"
import styled from "styled-components"

const DisplayH1 = ({ children }) => {
	return <H5>{children}</H5>
}

export default DisplayH1

const H5 = styled.h5`
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 35px;
	line-height: 40px;
`
