import React from "react"

const YellowLabelComponent = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="305"
			height="83"
			fill="none"
			viewBox="0 0 305 83"
		>
			<path fill="#FFF200" d="M254 0H0v83h305L254 0z"></path>
			<path fill="url(#paint0_linear)" d="M254 0H0v83h305L254 0z"></path>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="305"
					x2="262.941"
					y1="0"
					y2="154.554"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFD600"></stop>
					<stop offset="1" stopColor="#FFF200"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}

export default YellowLabelComponent
