import React from "react"
import styled from "styled-components"

const WorkingExperienceComponent = ({ data }) => {
  return (
    <>
      {data.length > 0 &&
        data.map(experience => (
          <WorkingExperience>
            <span className="year">{experience.year}</span>
            <span className="experience-title">
              {experience.experienceTitle}
            </span>
            <span className="list-title">PROJECTS HANDLED</span>
            <ul>
              {experience.projectHandled.map(project => (
                <li key={project}>{project}</li>
              ))}
            </ul>
            <span className="list-title">DUTIES AND RESPONSIBILITIES</span>
            <ul>
              {experience.duties.map(duty => (
                <li key={duty}>{duty}</li>
              ))}
            </ul>
          </WorkingExperience>
        ))}
    </>
  )
}

export default WorkingExperienceComponent

const WorkingExperience = styled.div`
  padding: 40px;
  @media (max-width: 575px) {
    padding: 40px 15px;
  }
  background: #f0f3f5;
  margin-bottom: 80px;
  position: relative;
  color: #2c2c2c;
  .year {
    position: absolute;
    top: -20px;
    right: 0;
    font-size: 82px;
    line-height: 30px;
    font-family: Abril Fatface;
    color: rgba(44, 44, 44, 0.15);
    @media (max-width: 991px) {
      font-size: 50px;
    }
  }
  .experience-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
  }
  .list-title {
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    display: block;
    margin: 10px 0;
  }
`
