import React from "react"
import styled from "styled-components"

const DisplayH1 = ({ children }) => {
	return <H2>{children}</H2>
}

export default DisplayH1

const H2 = styled.h2`
	margin-bottom: 20px;
	font-size: 66px;
	line-height: 72px;
	font-weight: bold;
`
