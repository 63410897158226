import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import { DisplayH4, HR, Button } from "~/components"
import { Link } from "gatsby"

const AboutSectionComponent = () => {
  return (
    <AboutSection>
      <Container>
        <Row>
          <Col md={5}>
            <DisplayH4>
              Here in DGO, we care on building your aspirations!
            </DisplayH4>
            <HR />
          </Col>
          <Col md={6}>
            <Description>
              <span>
                We always ascertain of carrying out satisfiable and quality
                output from the work that we provide.
              </span>
              Further, we are definitely receptive in negotiations with our
              partners and clients as such when it comes to discussions of
              adjustment with the construction expenses and yet still
              accomplishing their requests and expectations. And most
              importantly, it’s our pride of valuing deadlines with reputable
              output on every construction service or project that we take.
              Because here in DGO, we care on building your aspirations!
            </Description>
          </Col>
        </Row>
        <ButtonArea>
          <Link to="about">
            <Button primary={true} width="172">
              ABOUT US
            </Button>
          </Link>
        </ButtonArea>
      </Container>
    </AboutSection>
  )
}

export default AboutSectionComponent

const AboutSection = styled.section`
  padding: 100px 0;
  text-align: left;
  h4 {
    color: #09175e;
    @media (max-width: 575px) {
      font-size: 35px;
    }
  }
  hr {
    float: left;
  }
`

const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2c;

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    display: block;
    margin-bottom: 20px;
  }
`

const ButtonArea = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 60px;
`
