import React from "react"
import styled from "styled-components"
import { DisplayH1, DisplayH4 } from "~/components"
import { Container } from "reactstrap"

const Footer = () => {
  return (
    <FooterSection>
      <TalkToUs>Have a project? Talk to us.</TalkToUs>
      <hr />
      <DisplayH1>
        <a href="tel:+649158518834">09158518834</a>
      </DisplayH1>
      <DisplayH4>
        <a href="mailto: daveoporto@gmail.com">daveoporto@gmail.com</a>
      </DisplayH4>
      <Copyright>
        <Container>
          <span>
            © {new Date().getFullYear()} DGO Construction and developed by AGDP
            Solutions. All Rights Reserved.
          </span>
          {/*
          <Messenger>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="73"
              height="74"
              fill="none"
              viewBox="0 0 73 74"
            >
              <path
                fill="#2196F3"
                d="M.333 34.168c0 10.645 5.269 20.143 13.507 26.346v12.902l12.344-6.827a38.738 38.738 0 0010.398 1.416c20.02 0 36.251-15.146 36.251-33.834C72.836 15.483 56.605.333 36.585.333 16.565.333.333 15.48.333 34.168zM32.76 24.69l9.456 9.924L60 24.691l-19.817 21.2-9.23-9.92-18.013 9.924L32.76 24.69z"
              ></path>
            </svg>
          </Messenger>
          */}
        </Container>
      </Copyright>
    </FooterSection>
  )
}

export default Footer

const FooterSection = styled.footer`
  text-align: center;
  padding: 60px 0;
  background: #09175e;
  color: #fff;
  hr {
    width: 130px;
    border-bottom: 5px solid #fff;
    margin-bottom: 60px;
    color: #09175e;
    border-top: unset;
  }
  h1 a {
    color: #fff200;
    @media (max-width: 575px) {
      font-size: 35px;
    }
  }
  h1 a:hover {
    text-decoration: none;
  }
  h4 a {
    color: #fff;
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
`

const TalkToUs = styled.span`
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #f0f3f5;
`

const Copyright = styled.div`
  height: 50px;
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  text-align: left;
  & > div {
    position: relative;
  }
  @media (max-width: 575px) {
    text-align: center;
  }
`

// const Messenger = styled.div`
//   width: 100px;
//   height: 100px;
//   background-color: #fff;
//   border-radius: 50px;
//   position: absolute;
//   bottom: -25px;
//   right: 0;
//   color: #000;
//   text-align: center;
//   padding: 16px 10px;
// `
