import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled, { keyframes } from "styled-components"
import FeaturedImage from "~/components/Images/FeaturedImage"
import { HR, DisplayH4, Button } from "~/components"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const FeaturedProjects = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allSanityProject(filter: { featured: { eq: true } }) {
            edges {
              node {
                id
                title
                featured
                location
                completedDate
                featuredImage {
                  asset {
                    fluid(maxWidth: 350) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                images {
                  asset {
                    fluid(maxWidth: 350) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const projects = data.allSanityProject.edges.map(
          project => project.node
        )

        return (
          <FeaturedContent>
            <Container>
              <DisplayH4>Featured Projects</DisplayH4>
              <HR />
              <Row>
                {projects.map(project => (
                  <Col md={4} key={project.id}>
                    <Card>
                      <FeaturedProject>
                        <FeaturedImageContainer>
                          {(project.featuredImage && (
                            <Img fluid={project.featuredImage.asset.fluid} />
                          )) || <FeaturedImage />}
                        </FeaturedImageContainer>
                        <Description className="description">
                          <Year>{project.completedDate || "Ongoing"}</Year>
                          <Title>{project.title}</Title>
                          <Details>{project.location}</Details>
                        </Description>
                      </FeaturedProject>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Link to="services-and-projects">
                <Button primary={true} width="246px">
                  VIEW MORE PROJECTS
                </Button>
              </Link>
            </Container>
          </FeaturedContent>
        )
      }}
    />
  )
}

export default FeaturedProjects

const opacity = keyframes`
    100% { opacity: 1;}

`

const FeaturedContent = styled.section`
  text-align: center;
  padding: 100px 0;
  h4 {
    color: #09175e;
    @media (max-width: 575px) {
      font-size: 24px;
    }
  }
  .row {
    justify-content: center;
  }
`

const Card = styled.div`
  width: 100%;
  height: 484px;
  margin-bottom: 80px;
  @media (max-width: 767px) {
    height: 100%;
    margin: 20px 0;
  }
`

const FeaturedProject = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  &:hover .description {
    animation-name: ${opacity};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
`

const Description = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff200;
  position: absolute;
  bottom: 0;
  opacity: 0;
  @media (max-width: 767px) {
    opacity: 1;
  }
`

const Year = styled.span`
  display: block;
  font-family: Abril Fatface;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 30px;
  text-align: right;
  color: #ffd600;
  @media (max-width: 991px) {
    font-size: 45px;
  }
`

const Title = styled.span`
  font-weight: bold;
  font-size: 21px;
  line-height: 21px;
  color: #2c2c2c;
  display: block;
  text-align: left;
  margin: 20px 0 10px 0;
  padding: 12px;
`

const Details = styled.span`
  font-size: 13px;
  line-height: 19px;
  display: block;
  text-align: left;
  max-width: 180px;
  padding: 12px;
`

const FeaturedImageContainer = styled.div`
  height: 484px;
  .gatsby-image-wrapper {
    height: 100%;
  }
`
