import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  body {
   	color: #373A3C;
   	font-family: PT Sans, sans-serif;
  }
  html {
    scroll-behavior: smooth;
  }
  p, span {
  	font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
    font-family: PT Sans, sans-serif;
  }
  p small {
  	font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 19px;
  }
  h1, h2, h3, h4, h5, h6 {
  	font-family: PT Sans, sans-serif;
  	color: #373A3C;
  			font-style: normal;
		font-weight: bold;
  }
  h1 {
		font-size: 30px;
		line-height: 30px;
  }
  h2 {
    font-size: 24px;
    line-height: 24px;
  }
  h3 {
    font-size: 21px;
    line-height: 21px;
  }
  h4 {
    font-size: 18px;
    line-height: 18px;
  }
  h5 {
    font-size: 16px;
    line-height: 16px;
  }
  h6 {
    font-size: 15px;
    line-height: 15px;
  }  
`
