import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const OrganizationalFlowComponent = ({ data }) => {
  return (
    <>
      {data && (
        <ImageArea>
          <Img fluid={data.asset.fluid} />
        </ImageArea>
      )}
    </>
  )
}

export default OrganizationalFlowComponent

const ImageArea = styled.div`
  height: auto;
  width: 600px;
  @media (max-width: 991px) {
    width: 100%;
  }
`
