import React from "react"
import styled from "styled-components"
import { Button } from "reactstrap"

const ButtonComponent = ({ children, primary, width }) => {
	return (
		<CustomButton primary={primary} width={width}>
			{children}
		</CustomButton>
	)
}

export default ButtonComponent

const CustomButton = styled(Button)`
	background-color: ${props => (props.primary ? "#09175e" : "#fff")};
	width: ${props => (props.width ? props.width : "100%")};
	color: ${props => (props.primary ? "#fff" : "#09175e")};
	height: 60px;
	border: 3px solid #09175e;
	padding: 0 30px;
`
