import React from "react"
import styled from "styled-components"

const HRComponent = () => {
	return <HR />
}

export default HRComponent

const HR = styled.hr`
	width: 130px;
	border-bottom: 5px solid #fff200;
	margin-bottom: 60px;
	color: #09175e;
	border-top: unset;
`
