import React from "react"
import styled from "styled-components"

const DisplayH1 = ({ children }) => {
	return <H1>{children}</H1>
}

export default DisplayH1

const H1 = styled.h1`
	margin-bottom: 20px;
	font-size: 72px;
	line-height: 79px;
	font-weight: bold;
`
