import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import AdditionalServicesImage from "~/components/Images/AdditionalServicesImage"
import { YellowLabel, Button } from "~/components"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const AdditionalServicesComponent = ({ buttonAreaDisplay }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allSanityService(sort: { fields: _createdAt }, limit: 6) {
            edges {
              node {
                id
                title
                image {
                  asset {
                    fluid(maxWidth: 350) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const services =
          data && data.allSanityService.edges.map(service => service.node)
        return (
          <AddtionalService>
            <Container>
              <h2>Additional Services</h2>
              <Row>
                {services.map(list => (
                  <Col lg={4} md={6} sm={12} key={list.id}>
                    <Card>
                      {(list.image && (
                        <Img fluid={list.image.asset.fluid} />
                      )) || <AdditionalServicesImage />}

                      <Label>
                        <YellowLabel />
                        <h3>{list.title}</h3>
                      </Label>
                    </Card>
                  </Col>
                ))}
              </Row>
              {buttonAreaDisplay ? (
                <ButtonArea>
                  <Link to="services-and-projects">
                    <Button primary={true} width={"246px"}>
                      LEARN MORE
                    </Button>
                  </Link>
                  <Button primary={false} width={"218px"}>
                    GET QUOTE
                  </Button>
                </ButtonArea>
              ) : null}
            </Container>
          </AddtionalService>
        )
      }}
    />
  )
}

export default AdditionalServicesComponent

const AddtionalService = styled.div`
  h2 {
    color: #09175e;
    margin-bottom: 40px;
  }
  .row {
    justify-content: center;
  }
`

const Card = styled.div`
  width: 350px;
  height: 350px;
  margin: 20px 0;
  position: relative;
  @media (max-width: 1199px) {
    width: 100%;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
  &:hover {
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.35);
  }
`

const Label = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  h3 {
    position: absolute;
    padding: 20px;
    max-width: 400px;
    top: 0;
    color: #2c2c2c;
    text-align: left;
    @media (max-width: 991px) {
      padding-right: 40px;
    }
  }

  @media (max-width: 1199px) {
    svg {
      width: 100%;
      height: 100%;
    }
  }
`

const ButtonArea = styled.div`
  text-align: center;
  margin-top: 70px;
  display: block;
  button {
    margin: 0 15px;
    @media (max-width: 767px) {
      margin: 20px 5px;
    }
  }
`
