import React from "react"
import styled from "styled-components"
import { SliderSection } from "~/components"
import { StaticQuery, graphql } from "gatsby"

const CompletedSectionComponent = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					allSanityProject(filter: { projectStatus: { eq: "completed" } }) {
						edges {
							node {
								title
								featured
								location
								workStatus
								completedDate
								projectStatus
								amount
								agency
								images {
									asset {
										url
									}
								}
							}
						}
					}
				}
			`}
			render={data => {
				const projects = data.allSanityProject.edges.map(
					project => project.node
				)
				return (
					<CompletedSection id="completed-projects">
						{projects.map((project, index) => {
							if (index % 2 === 0) {
								return (
									<SliderSection
										project={project}
										reverse={false}
										bgblue={false}
									/>
								)
							} else {
								return (
									<SliderSection
										project={project}
										reverse={true}
										bgblue={false}
									/>
								)
							}
						})}
					</CompletedSection>
				)
			}}
		/>
	)
}

export default CompletedSectionComponent

const CompletedSection = styled.div``
