import React from "react"
import styled from "styled-components"
import {
  Container,
  Row,
  Col,
  FormGroup,
  UncontrolledCarousel,
} from "reactstrap"
import { ContactBG } from "~/components"
import TestImage from "~/images/Banner.jpg"

const items = [
  {
    src: TestImage,
    altText: "Slide 1",
    key: "1",
  },
  {
    src: TestImage,
    altText: "Slide 2",
    key: "2",
  },
  {
    src: TestImage,
    altText: "Slide 3",
    key: "3",
  },
]

const HomeBanner = () => {
  return (
    <Banner>
      <BannerArea>
        <UncontrolledCarousel items={items} />
      </BannerArea>
      <Container>
        <Row>
          <Col md={6} sm={12}>
            <TextArea>
              <Title>DGO CONSTRUCTION</Title>
              <hr />
              <Quote>We care on building your aspirations.</Quote>
            </TextArea>
          </Col>
          <Col md={6} sm={12}>
            <ContactArea>
              <Background>
                <ContactBG />
              </Background>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="99"
                height="99"
                fill="none"
                viewBox="0 0 99 99"
              >
                <path
                  fill="#FFD600"
                  d="M33.242 33.243l65.115.608L33.85 98.357l-.608-65.114z"
                ></path>
              </svg>
              <ContactForm>
                <p>Get a Free Quote!</p>
                <span>Complete the form below:</span>
                <form
                  name="contact-dgo"
                  method="POST"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact-dgo" />
                  <FormGroup>
                    <input
                      type="text"
                      name="fullname"
                      placeholder="Name*"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <input
                      type="text"
                      name="number"
                      placeholder="Contact Number*"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address*"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <input
                      type="textarea"
                      name="description"
                      placeholder="How can we help you?*"
                      className="form-control description"
                    />
                  </FormGroup>
                  <CustomButton type="submit" primary={true}>
                    Submit
                  </CustomButton>
                </form>
              </ContactForm>
            </ContactArea>
          </Col>
        </Row>
      </Container>
    </Banner>
  )
}

export default HomeBanner

const Banner = styled.section`
  display: block;
  text-align: left;
  color: #fff;
  position: relative;
  min-height: 750px;
  width: 100%;
  margin-bottom: 100px;
  @media (max-width: 575px) {
    margin-bottom: 0px;
  }
  .carousel-indicators {
    right: unset;
  }
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  .carousel-inner {
    height: 770px;
    @media (max-width: 575px) {
      height: 100vh;
    }
  }
`

const BannerArea = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.5);
  & > div {
    @media (max-width: 1199px) {
      .carousel-item {
        height: inherit;
        img {
          height: inherit;
        }
      }
    }
    @media (max-width: 767px) {
      .carousel-indicators {
        right: 0;
        bottom: 125px;
        @media (max-width: 575px) {
          bottom: 135px !important;
        }
        @media (max-width: 411px) {
          bottom: 100px !important;
        }
      }
    }
  }
`

const TextArea = styled.div`
  padding: 170px 0;
  @media (max-width: 575px) {
    padding: 100px 0;
  }
  hr {
    border-top: 5px solid #fff200;
    width: 130px;
    margin: 20px 0;
  }
`

const Title = styled.h1`
  font-style: normal;
  font-size: 72px;
  line-height: 79px;
  color: #fff;
  @media (max-width: 575px) {
    font-size: 35px;
  }
`

const Quote = styled.span`
  font-style: italic;
  font-size: 42px;
  line-height: 46px;
  @media (max-width: 575px) {
    font-size: 30px;
  }
`

const ContactArea = styled.div`
  width: 445px;
  height: 592px;
  float: right;
  position: absolute;
  bottom: -290px;
  right: 0;
  @media (max-width: 767px) {
    position: unset;
    float: unset;
    height: inherit;
    width: 100%;
  }
  @media (max-width: 575px) {
    height: 680px;
    width: inherit;
  }
  @media (max-width: 411px) {
    height: 550px;
  }
  svg {
    position: absolute;
    bottom: 31px;
    right: 17px;
    width: 90px;
    @media (max-width: 767px) {
      bottom: -140px;
      right: 33px;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;

  height: inherit !important;
  .gatsby-image-wrapper {
    height: inherit !important;
  }
  @media (max-width: 575px) {
    padding: 0 15px;
  }
`

const ContactForm = styled.div`
  height: 100%;
  padding: 50px;
  margin-left: 10px;
  color: #2c2c2c;
  text-align: center;
  @media (max-width: 575px) {
    margin-left: 0;
  }
  @media (max-width: 411px) {
    padding: 50px 20px;
  }
  p {
    margin-bottom: 2px;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
  }
  form {
    margin-top: 20px;
  }
  textarea {
    height: 100px;
  }
  input {
    height: 43px;
  }
  .description {
    height: 100px;
  }
`

const CustomButton = styled.button`
  background-color: ${props => (props.primary ? "#09175e" : "#fff")};
  width: ${props => (props.width ? props.width : "100%")};
  color: ${props => (props.primary ? "#fff" : "#09175e")};
  height: 60px;
  border: 3px solid #09175e;
  padding: 0 30px;
`
