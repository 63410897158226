import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import GetInTouchBackground from "~/images/right-menu.svg"
import Logo from "~/components/Images/Logo"
import Sidebar from "~/components/Common/sidebar"

const Header = ({ siteTitle }) => (
  <HeaderSection>
    <Container>
      <Row>
        <Col md={3}>
          <Link to="/">
            <LogoArea>
              <Logo />
            </LogoArea>
          </Link>
        </Col>
        <Col md={8}>
          <MenuArea>
            <Menu>
              <List>
                <Link to="/">Home</Link>
              </List>
              <List>
                <Link to="/services-and-projects">Services & Projects</Link>
              </List>
              <List>
                <Link to="/about">About Us</Link>
              </List>
            </Menu>
          </MenuArea>
        </Col>
      </Row>
    </Container>
    <Contact background={GetInTouchBackground}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="55"
        height="55"
        fill="none"
        viewBox="0 0 55 55"
      >
        <circle
          cx="27.5"
          cy="27.5"
          r="26"
          stroke="#FFF200"
          strokeWidth="3"
        ></circle>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="31"
        fill="none"
        viewBox="0 0 25 31"
        className="phone"
      >
        <path
          fill="#FFF200"
          d="M22.206 14.667l-4.387-4.387V2.725A2.728 2.728 0 0015.094 0H3.227A2.728 2.728 0 00.502 2.725v25.55A2.728 2.728 0 003.227 31h18.527a.908.908 0 00.89-.73l1.693-8.424c.529-2.632-.268-5.316-2.131-7.179zM3.227 1.817h11.867c.501 0 .908.407.908.908v11.714a2.844 2.844 0 00-4.01.014 2.844 2.844 0 00-.007 4.018l3.479 3.616c-.754.563-1.731 1.546-2.433 3.161H2.32V2.725c0-.501.407-.909.908-.909zM2.32 28.274v-1.21h10.125c-.144.636-.246 1.34-.29 2.119H3.226a.91.91 0 01-.908-.909zm20.237-6.787l-1.546 7.696h-7.036c.131-1.93.686-3.546 1.625-4.713.78-.968 1.572-1.314 1.635-1.34a.9.9 0 00.331-1.48l-4.289-4.458a1.029 1.029 0 011.453-1.458c1.92 1.964.987 1.021 4.347 4.381a.908.908 0 101.284-1.284L17.82 16.29v-3.442l3.102 3.102c1.433 1.433 2.044 3.503 1.636 5.537z"
        ></path>
      </svg>
      <ContactNumber>
        <span>GET IN TOUCH</span>
        <p>
          <a href="tel:+649158518834">09158518834</a>
        </p>
      </ContactNumber>
      <Sidebar />
    </Contact>
  </HeaderSection>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header

const HeaderSection = styled.header`
  display: block;
  height: 110px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
`
const LogoArea = styled.div`
  line-height: 60px;
  width: 118px;

  height: 100%;
  padding-top: 10px;
`
const MenuArea = styled.div`
  display: flex;
  float: left;
  @media (max-width: 767px) {
    display: none;
  }
`
const Menu = styled.ul`
  text-align: right;
  display: flex;
  text-align: right;
  float: right;
  list-style: none;
  margin-left: 20px;
  margin-bottom: 0;
  height: 110px;
  padding-top: 6px;
  a {
    text-decoration: unset;
    color: #2c2c2c;
  }
  @media (max-width: 1199px) {
    padding-left: 0;
  }
  @media (max-width: 991px) {
    margin-left: -20px;
  }
`

const List = styled.li`
  line-height: 60px;
  margin: 20px 25px;
  height: 50px;
  padding-bottom: 5px;
  &:hover {
    border-bottom: 4px solid #fff200;
  }
  @media (max-width: 1199px) {
    margin: 20px;
  }
  @media (max-width: 992px) {
    margin: 20px 10px;
  }
`

const Contact = styled.div`
  background: ${props => `url(${props.background})`};
  height: 110px;
  width: 420px;
  text-align: center;
  display: flex;
  padding: 30px 50px;
  position: absolute;
  right: 0;
  top: 0;
  .phone {
    position: absolute;
    top: 40px;
    left: 66px;
  }
  @media (max-width: 1199px) {
    width: unset;
  }
  @media (max-width: 767px) {
    svg {
      display: none;
    }
    align-items: center;
  }
`

const ContactNumber = styled.div`
  color: #fff;
  text-align: left;
  margin-left: 20px;
  span {
    display: block;
    margin-bottom: 3px;
  }
  p {
    font-size: 30px;
  }
  p a {
    color: #fff;
  }
  p a:hover {
    text-decoration: none;
  }
  @media (max-width: 991px) {
    p {
      font-size: 18px;
    }
    span {
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
`
