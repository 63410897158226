import React from "react"
import styled from "styled-components"
import { Container, Row, Col, UncontrolledCarousel } from "reactstrap"
import { DisplayH5 } from "~/components"
import arrow from "~/images/arrow.png"

const SliderSection = ({ project, reverse, bgblue }) => {
  const {
    title,
    location,
    workStatus,
    projectStatus,
    agency,
    typeOfContract,
    images
  } = project

  const imageData = []
  images.map(image =>
    imageData.push({
      src: image.asset.url,
      key: image.asset.url,
      altText: "Slide 1"
    })
  )

  return (
    <Container>
      <Row>
        <Col md={{ size: 5, order: `${reverse ? 2 : null}` }}>
          {bgblue ? (
            <SlideDetailsBlue>
              <CompletedYear>
                {projectStatus}
                <span>{new Date().getFullYear()}</span>
              </CompletedYear>
              <DisplayH5>{title}</DisplayH5>
              <p>{location}</p>
              <SlideList>
                <li>
                  <small>Type of Contract</small>
                  <p>{typeOfContract || "Not Specified"}</p>
                </li>
                <li>
                  <small>Work Status</small>
                  <p>{workStatus}</p>
                </li>
                {/*<li>
                  <small>Amount</small>
                  <p>PHP {amount.toLocaleString()}</p>
                </li>
                */}
                <li>
                  <small>AGENCY/CLIENT</small>
                  <p>{agency}</p>
                </li>
              </SlideList>
            </SlideDetailsBlue>
          ) : (
            <SlideDetails>
              <CompletedYear>
                {projectStatus}
                <span>{new Date().getFullYear()}</span>
              </CompletedYear>
              <DisplayH5>{title}</DisplayH5>
              <p>{location}</p>
              <SlideList>
                <li>
                  <small>Type of Contract</small>
                  <p>{typeOfContract || "Not Specified"}</p>
                </li>
                <li>
                  <small>Work Status</small>
                  <p>{workStatus}</p>
                </li>
                {/*
                <li>
                  <small>Amount</small>
                  <p>{amount.toLocaleString()}</p>
                </li>
                */}
                ====
                <li>
                  <small>AGENCY/CLIENT</small>
                  <p>{agency}</p>
                </li>
              </SlideList>
            </SlideDetails>
          )}
        </Col>
        <Col md={7}>
          <SlideItem>
            <UncontrolledCarousel items={imageData} />
          </SlideItem>
        </Col>
      </Row>
    </Container>
  )
}

export default SliderSection

const SlideItem = styled.div`
  margin-bottom: 50px;
  .carousel-control-prev-icon {
    background-image: url(${arrow});
  }
  .carousel-control-next-icon {
    background-image: url(${arrow});
    transform: rotate(180deg);
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 30px;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 100%;
  }
  .carousel-inner {
    height: auto;
  }
  .carousel-item {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
  }
  @media (min-width: 991px) {
    .carousel-inner {
      height: 576px;
    }
  }
`
const SlideDetails = styled.div`
  background: #fff200;
  padding: 25px;
  padding-top: 70px;
  position: relative;
  text-align: left;
  height: 576px;
  h5 {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 0;
    @media (min-width: 767px) {
      font-size: 25px;
      line-height: 32px;
    }
    @media (min-width: 575px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
`
const SlideDetailsBlue = styled.div`
  background: #09175e;
  padding: 25px;
  padding-top: 70px;
  position: relative;
  text-align: left;
  color: #fff;
  height: 576px;
  h5 {
    color: #fff;
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 0;
    @media (min-width: 767px) {
      font-size: 25px;
      line-height: 32px;
    }
    @media (min-width: 575px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
`
const SlideList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  small {
    text-transform: uppercase;
    font-size: 10px;
  }
`
const CompletedYear = styled.div`
  position: absolute;
  right: 0;
  top: -10px;
  font-size: 30px;
  color: #ffd600;
  font-family: Abril Fatface;
  span {
    font-size: 60px;
    @media (max-width: 991px) {
      font-size: 40px;
    }
    @media (max-width: 767px) {
      font-size: 60px;
    }
    @media (max-width: 575px) {
      font-size: 40px;
    }
  }
  @media (max-width: 991px) {
    font-size: 25px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (max-width: 575px) {
    font-size: 25px;
  }
`
