import React from "react"
import styled from "styled-components"
import { Table } from "reactstrap"

const EquipmentListComponent = ({ data }) => {
	return (
		<EquipmentSection>
			{data.length > 0 && (
				<Table striped>
					<thead>
						<tr>
							<th colspan="2" scope="col">
								QUANTITY
							</th>
							<th scope="col">DESCRIPTION</th>
						</tr>
					</thead>
					<tbody>
						{data.map(equipment => (
							<tr>
								<td scope="row">{equipment.quantity}</td>
								<td>unit</td>
								<td>{equipment.title}</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</EquipmentSection>
	)
}

export default EquipmentListComponent

const EquipmentSection = styled.div`
	table {
		border-collapse: collapse;
		border-radius: 15px;
		border-style: hidden;
		box-shadow: 0 0 0 1px #c8dade;
	}

	th,
	td {
		padding: 14px 25px;
	}
	th {
	}
	td {
		border: 1px solid #c8dade;
	}
	thead th {
		background: #09175e;
		color: #fff;
		border-spacing: 0;
	}
	th:first-child {
		border-top-left-radius: 15px;
	}
	th:last-child {
		background: #ffd600;
		color: #000;
		border-top-right-radius: 15px;
	}
`
