import React from "react"
import styled from "styled-components"
import { Row, Col, Container } from "reactstrap"
import { StaticQuery, graphql } from "gatsby"

const Status = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					allSanityProject {
						edges {
							node {
								projectStatus
							}
						}
					}
				}
			`}
			render={data => {
				const projects = data.allSanityProject.edges.map(list => list.node)
				const completed = projects.filter(
					project => project.projectStatus === "completed"
				).length
				const ongoing = projects.filter(
					project => project.projectStatus === "ongoing"
				).length
				return (
					<StatusSection>
						<Container fluid>
							<Row>
								<Col md={6}>
									<Completed>
										<Total>{completed}</Total>
										<p>completed</p>
									</Completed>
								</Col>
								<Col md={6}>
									<Ongoing>
										<Total>{ongoing}</Total>
										<p>ongoing</p>
									</Ongoing>
								</Col>
							</Row>
						</Container>
					</StatusSection>
				)
			}}
		/>
	)
}

export default Status

const StatusSection = styled.section`
	text-align: center;
	margin-bottom: 50px;
	.row div {
		padding-right: 0;
		padding-left: 0;
	}
	p {
		font-size: 32px;
		color: #2c2c2c;
	}
`

const Completed = styled.div`
	width: 100%;
	background-color: #fff200;
	padding: 40px 0;
`

const Ongoing = styled.div`
	width: 100%;
	background-color: #ffd600;
	padding: 40px 0;
`

const Total = styled.span`
	font-family: Abril Fatface;
	font-size: 92px;
	line-height: 79px;
	color: #2c2c2c;
	display: block;
	margin-bottom: 10px;
`
