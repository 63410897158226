import React from "react"
import { Link } from "gatsby"
import { Nav, NavItem } from "reactstrap"
import styled from "styled-components"
import { HR } from "~/components"

export default () => (
  <SideBarWrap>
    <span></span>
    <Nav>
      <NavItem>
        <Link to="/">HOME</Link>
      </NavItem>
      <NavItem>
        <Link to="/services-and-projects">Services and Projects</Link>
      </NavItem>
      <NavItem>
        <Link to="/about">About Us</Link>
      </NavItem>
      <NavItem>
        <HR />
      </NavItem>
      <NavItem>
        <p>
          <small>GET IN TOUCH</small>
        </p>
        <Phone href="tel:0915-851-8834">0915-851-8834</Phone>
      </NavItem>
    </Nav>
  </SideBarWrap>
)

const SideBarWrap = styled.div`
  padding-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
  .nav-item {
    display: block;
    width: 100%;
    margin: 20px 0;
    a {
      color: #fff;
      text-d
    }
    small {
      color: #fff;
    }
    hr {
      width: 75px;
      border-bottom: 3px solid #fff200;
    }
    p {
      margin-bottom: 0;
    }
  }
`
const Phone = styled.a`
  font-size: 25px;
`
