import React from "react"
import styled from "styled-components"

const DisplayH1 = ({ children }) => {
	return <H3>{children}</H3>
}

export default DisplayH1

const H3 = styled.h3`
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 54px;
	line-height: 59px;
`
